import React from 'react';
import { Card } from '@components/Card';
import styled from 'styled-components';
import { Heading } from '@atoms';
// import { graphql, useStaticQuery, Link } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const BlogDetailCardDeck = ({ blogPosts, heading, headingSize, kicker, subhead }) => {

  // heading, headingSize, subheading, cards

  return (
    <CardDeckWrapper>
      {kicker && <div style={{ paddingBottom: '16px', textAlign: 'center', color: '#002DC2', fontSize: '14px', letterSpacing: '2px', fontFamily: 'Roboto', fontWeight: 'bold', textTransform: 'uppercase' }}>{kicker}</div>}
      {heading && 
        <Heading 
          headingSize={headingSize || 'H3'}
          style={{ textAlign: 'center', paddingBottom: '24px' }}
        >
          {heading}
        </Heading>
      }
      {subhead?.raw && <div style={{ margin: '0 auto', paddingBottom: '48px', maxWidth: '760px', textAlign: 'center', fontSize: '16px', fontFamily: 'Roboto', color: '#4D4D4D' }}>{documentToReactComponents(JSON.parse(subhead?.raw))}</div>}
      <CardDeckGrid
        data-comp={BlogDetailCardDeck.displayName}
      >
        { blogPosts && blogPosts.map(card => {
          return (

            <Card
              gatsbyImage={card?.node?.featuredImage?.gatsbyImageData}
              featuredImg={card?.node?.featuredImage?.file?.url}
              title={card?.node?.title}
              tag={card?.node?.tag}
              description={card?.node?.description?.description}
              author={card?.node?.author}
              link={`/blog/${card?.node?.slug}`}
              blogBody={card?.node?.body}
            />
          );
        })}
      </CardDeckGrid>
    </CardDeckWrapper>
  );
};

BlogDetailCardDeck.displayName = 'BlogDetailCardDeck';

const CardDeckWrapper = styled.div`
  background: #E4E9F1;
  padding: 100px 60px;
  @media (max-width: 992px) {
    padding: 80px 40px;
  }
  @media (max-width: 600px) {
    padding: 80px 20px;
  }
`

const CardDeckGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1200px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`