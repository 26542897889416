import styled from 'styled-components';

export const PostContentWrapper = styled.div`
  white-space: pre-wrap;
  padding: 60px;
  // overflow-x: hidden !important;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 992px) {
    padding: 60px 40px;
  }

  @media (max-width: 576px) {
    padding: 60px 20px;
  }

`

export const HorizontalScroll = styled.div`
  height: 4px;
  width: 100%;
  background: #002DC2;
  position: sticky;
  top: 100px;
  margin: 0 0 0 auto;
  z-index: 99998;
  &.horizontal-scroll-mobile {
    display: none;
  }
  @media (max-width: 576px) {
    &.horizontal-scroll {
      display: none;
    }
    &.horizontal-scroll-mobile {
      display: block;
    }
  }
`;

export const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: -45px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 10px!important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset!important;
    height: unset!important;
  } 
  @media (max-width: 768px) {
    margin-top: -100px;
  }
  ${({ showScrollToTop }) =>
  `
    visibility: ${ showScrollToTop ? 'visible' : 'hidden' };
    opacity: ${ showScrollToTop ? '100%' : '0%' };
  `}
`

export const PostContentGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 32px;
  overflow: visible;
  grid-template-columns: 2fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const BodyContentWrapper = styled.article`
  white-space: normal;
  color: #1B1B1B;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  img {
    max-width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 25px;
  }
${({ theme }) =>
`
  font-family: ${theme.font.primary};
`}
`

export const FootnoteCaption = styled.div`
  padding-bottom: 32px;
  font-size: 12px;
  font-family: Roboto, 'Roboto', sans-serif; 
  color: #1B1B1B;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`
