import styled from "styled-components";

export const HeroContainer = styled.div`
  font-family: Roboto;
  position: relative;
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: hidden;
  margin-top: 100px;
  @media (max-width: 576px) {
    background: #fff;
    height: auto;
  }
  .desktop-hero {
    position: absolute;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    min-height: 100%;
    img {
      height: 100%;
    }
  }
`

export const Blur = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  width: 70%;
  height: 100%;
  transform: translateY(-50%);
  backdrop-filter: blur(15px);
  background: #00000066;
  mask-image: linear-gradient(to right, #000000 60%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, #000000 60%, transparent 100%);
  @media (max-width: 576px) {
    display: none;
  }
`

export const TextContentContainer = styled.div`
  position: absolute;
  z-index: 100;
  padding: 24px 60px;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, -50%);
  @media (max-width: 992px) {
    padding: 40px;
  }
  @media (max-width: 576px) {
    padding: 20px;
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    h2 {
      color: #000 !important;
    }
  }
`

export const BreadCrumbFlex = styled.div`
  position: absolute;
  z-index: 100;
  padding: 24px 60px;
  top: 0%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, 0%);
  display: flex;
  align-items: baseline;
  a {
    text-decoration: none;
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #DDDDDD;
    }
  }
  @media (max-width: 992px) {
    padding: 24px 40px;
  }
  @media (max-width: 576px) {
    position: relative;
    padding: 20px;
    a span {
      color: #0000dd;
    }
    span {
      color: #000;
      &:hover {
        color: #00000099;
      }
    }
  }
`

export const CategoriesFlex = styled.div` 
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const CategoryTag = styled.div`
  margin-right: 8px;
  border-radius: 100px;
  padding: 4px 12px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`

export const DetailTextFlex = styled.div` 
  padding-top: 7px;
  display: flex;
  align-items: middle;
  font-size: 14px;
  .hero-blog-detail--detail-text-item {
    display: flex;
    align-items: middle;
    font-size: 14px;
    color: white;
    padding-right: 24px;
    .hero-blog-detail--detail-text-item-icon {
      width: 22px;
      img {
        height: 16px;
        width: auto;
      }
    }
  }
  @media (max-width: 768px) {
    display: inline-block;
    padding-top: 12px;
    .hero-blog-detail--detail-text-item {
      padding-top: 9px;
    }
  }
  @media (max-width: 576px) {
    .hero-blog-detail--detail-text-item {
      color: #000;
    }
  }
`
export const MobileFeaturedImage = styled.div`
  max-height: 320px;
  width: 100%;
  display: none;
  @media (max-width: 576px) {
    display: block;
  }
  .mobile-hero {
    z-index: auto;
    max-height: 320px;
    img {
      width: 100%;
    }
  }
`;

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
  @media (max-width: 576px) {
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
`;