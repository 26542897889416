import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const BlogDetailRelatedShareDesktop = ({ allPosts, relatedCategories }) => {
  const thisPostCategories = relatedCategories.map(category => category?.title);

  const relatedArticles = allPosts.filter(post => {
     return post?.node?.category.some(cat => thisPostCategories.includes(cat?.title))
    });
  const displayedArticles = relatedArticles.slice(0, 5);

  return (
    <RelatedArticlesWrapper
      data-Related={BlogDetailRelatedShareDesktop.displayName}
    >
      <Kicker>Related Articles</Kicker>
      <BorderLine />
      { displayedArticles && displayedArticles.map(article => {
        return (
          <Link style={{ textDecoration: 'none', color: '#1B1B1B' }} to={'/blog/' + article?.node?.slug}>
            <RelatedArticleListItem>{article?.node?.title}</RelatedArticleListItem>
          </Link>
        )
      })}
    </RelatedArticlesWrapper>
  );
};

BlogDetailRelatedShareDesktop.displayName = 'BlogDetailRelatedShareDesktop';

const RelatedArticlesWrapper = styled.div`
  font-family: Roboto;
  padding: 24px;
  border: 1px solid #F0F0F0;
  border-radius: 9px;
  width: 100%;
  min-height: 400px;
`

const Kicker = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4E4E4E;
`

const BorderLine = styled.div`
  margin: 16px 0px; 
  width: 100%;
  height: 1px;
  background: #F0F0F0;
`

const RelatedArticleListItem = styled.div`
  padding: 14px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #0E0E0E;
  transition: color 150ms ease;
  &:hover {
    color: #525252;
  }
`